@import "../styles/base/reset";

@import "../styles/base/variables";

@import "../styles/base/mixins";

// icons
@import "../icons/style.css";//new icons
.fa {color: $action_icon_gray} // awesome

// elements
@import "../styles/base/scrollbar";
@import "../styles/base/selects";
@import "../styles/base/fonts";
@import "../styles/base/buttons";
@import "../styles/base/links";
@import "../styles/base/checkbox";
@import "../styles/base/radio";
@import "../styles/base/headings";
@import "../styles/base/inputs";
@import "../styles/base/badges";
@import "../styles/base/modals";
@import "../styles/base/tabs";
@import "../styles/base/dropdown";
@import "../styles/base/containers";
@import "../styles/base/control-settings";
@import "../styles/base/tooltip";
@import "../styles/base/animations";
@import "../styles/base/date-picker";

@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");
@import "../styles/base/wisywig";
// -----

@import "./global.scss";
@import "./global-search-global.scss";
@import "./global-dashboard.scss";
@import "./global-buid-process-drag.scss"; // all drag events in build process
@import "./global-workflow.scss";
@import "./global-form-view.scss";
@import "./global-team.scss";
