@import 'variables';
@import 'mixins';

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $pale-grey;
  border-radius: 16px;
  margin: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 $action_icon_gray-3;
  background-color: $athens_gray;
  border: solid 1px $action_icon_gray-6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $porcelain;
}



/*scroll for dropdown*/

/* width */
.ui-dropdown-panel .ui-dropdown-items-wrapper {
  @include dropdown_scrollbar;
}

