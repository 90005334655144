.ef-amount-label {
    display: inline-block;
    height: 21px;
    line-height: 21px;
    vertical-align: middle;
    background-color: $gray_light;
    padding: 0 6px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $brand_gray;
    margin-left: 7px;
}

.ef-team-user-position {
  opacity: 0.6;
  font-size: 12px;
  color: $brand_gray;

  >span {
    display: inline-block; 
    &:first-letter {
      text-transform: uppercase;
    }
  }

  i {
    font-size: 11px;
  }
}