
@import "variables";

// STYLES FOR NGX-DND plugin

// ! ATTENTION - IT IS VERY DIFFICULT STYLES WITH NGX-DND directive (be careful). MUST USED ONLY GLOBAL

@mixin stage_item_drag_shadow () {
  width: $ef-wf-stage-card-width;
  position: relative;
  margin: 0;
  margin-bottom: $process-build-height-to-next-stage;
  overflow: visible;

  // top vertical line
  &:before {
    height: $process-build-height-to-next-stage;
    position: absolute;
    left: 50%;
    bottom: -$process-build-height-to-next-stage;
    content: '';
    align-self: flex-start;
    border-left: solid 1px $process-build-between-stage-line-color;
    box-sizing: border-box;   
  }
}

.ef-build-process-drag-menu {
  position: fixed;
  left: 0;
  top: $ef-app-header-height + $ef-process-build-header-panel-height;
  bottom: 0;
  width: $ef-process-build-create-menu-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $gray_ultra-dark;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-y: scroll;
  z-index: 1; // !
  
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

// DRAG MENU (main styles)

  // division of menu 
  .ef-drag-menu-division {
    margin-left: 24px;
    margin-right: 16px;
    margin-top: 24px;

    &._additional {
      margin-top: 35px;
    }
  }
  
  // division - title
  .ef-drag-menu-division-title {
    display: block;
    font-size: 14px;
    text-align: left;
    color: $gray_settings;
    margin-bottom: 15px;
    &:first-letter {text-transform: uppercase;}
  }
  
  // childs of division
  .ef-drag-menu-division-childs {
    transition: all .2s linear;
  }

  .ef-drag-menu-division-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:not(:last-child) {
      margin-bottom: 16px;  
    }
  }
  
  // child item
  .ef-drag-menu-item {
    width: 104px;
    height: 104px;
    position: relative;
    border-radius: 3px;
    
    box-shadow: 0 1px 2px 0 rgba(50, 72, 103, 0.7);
    overflow: hidden;

    &:nth-child(odd) {
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 11px 30px -7px rgba(0,0,0,0.3);
      transition: box-shadow .2s linear;
    }
  }
  
  // item label
  .ef-drag-menu-item-label {
    @include ef_flex_c_c; 
    flex-direction: column; 
    width: 100%;
    height: 100%;
    background-color: #4f688c;
    cursor: grab;
  }
  
  // item label - title
  .ef-drag-menu-title {
    font-size: 10px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    color: $pale-grey;
    max-width: 100%;
    padding: 0 5px;
  }
  
  // item label img
  .ef-drag-menu-img {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 9px;
  }

  
// DRAGGED ROTATED ELEMENT (stages, section, form-controls)
  
  .gu-mirror {
    display: block;
    border: 1px dashed rgba(83, 111, 150, 0.3);
    cursor: grabbing;
    border-radius: 2px;
    opacity: $ef-gu-mirror-opacity;
    display: flex;

    -moz-transform: rotate(-3deg); /* Для Firefox */
    -ms-transform: rotate(-3deg); /* Для IE */
    -webkit-transform: rotate(-3deg); /* Для Safari, Chrome, iOS */
    -o-transform: rotate(-3deg); /* Для Opera */
    transform: rotate(-3deg);

    ef-form-control {
      width: 100%;
    }

    &._forbidden {
      display: none;
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
  

//  BLUE SHADOW FOR DRAGGED ELEMENT (stages, section, form-controls)
  
  .gu-transit {
    opacity: $ef-gu-transit-opacity;
    box-shadow: none!important;

    // stages 
    &.ef-stage-item, //- change order (drag in workflow page) 
    &.ef-drag-menu-item._stage // drag from left menu
    {
      >* {
        display: none;
      }

      >*:last-child {
        display: block;
        overflow: hidden;
        height: 48px;
        width: $ef-wf-stage-card-width;
        border-radius: $ef-gu-transit-border-radius;
        background-color: $ef-gu-transit-bg-color;
        box-shadow: $ef-gu-transit-box-shadow;

        >* {
          display: none!important;
        }
      }
    }
    
    // section, controls
    &.ef-drag-menu-item._form_control, // from left drag menu
    &.ef-drag-menu-item._section, //from left drag menu
    &.ef-form-control-moved, // change order of controls
    &.ef-card-section-drag // change order of section
    {  
      min-width: 50px;
      display: block;
      height: 48px;
      overflow: hidden;
      position: relative;
      border-radius: 2px;
      background-color: $ef-gu-transit-bg-color;
      box-shadow: $ef-gu-transit-box-shadow;
      
      >* {
        display: none!important;
      }  
    }

    &._forbidden {
      background-color: red;
    }
  }

  // STAGE IN WORKFLOW shadow - hide bottom margin for form controls if shadow is last in list
  .ef-workflow-build {
    >div {
      &:last-child {
        &.gu-transit {
          margin-bottom: 0!important;  //(was 30px)

          // hide bottom line
          &:before {
            opacity: 0;
          }
        }
      }
    }
  }

  // FORM CONTROL shadow - hide bottom margin for form controls if shadow is last in list
  .ef-stage-section-layout {
    >div {
      &:last-child {
        &.gu-transit {
          margin-bottom: 0!important;  //(was 8px)
        }
      }
    }
  }

// DRAG FROM LEFT MENU TO FORM BUILDER ( STAGE | SECTION | CONTROLS ) 
  
  // STYLES FOR DRAG ITEM
  
  .ef-drag-menu-item {
    
    // rotated drag element (common for stage, section, form control)
    &.gu-mirror {
      position: relative;
      height: auto;
      height: auto!important;
      min-width: 300px;
      border: none;
      background-color: transparent;
      box-shadow: none;

      .ef-drag-menu-item-label {
        display: none;
      }

      .ef-drag-menu-item-component {
        display: block;

        // for grabbing cursor above all elements in component
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background-color: transparent;
          cursor: grabbing;
        }
      }
    }

    // shadow under drag element (blue) (common for stage, section, form control)
    &.gu-transit {
      position: relative;
      // opacity: 1;
      display: block;
      height: 50px;
      overflow: hidden;
    }

    // STAGE 
    
      &._stage {

        // drag shadow (blue)
        &.gu-transit {
          @include stage_item_drag_shadow;
          margin-left: auto;
          margin-right: auto;
        } 
        
        // dragged rotated element
        &.gu-mirror {
          overflow: visible;

          // hide lines between cards for split type
          .js_split_type {
            &:after,
            &:before {
              display: none!important;
            }  
          }
          
          // hide top card lines
          .ef-stage-card {
            &:before {
              display: none!important;
            }
          }
        }
      }
    
    // SECTION
    
      &._section {
        
        // dragged rotated element
        &.gu-mirror {
          // opacity: 1;
          width: auto!important;

          // control styles
          .ef-drag-menu-item-component {
            border: dashed 1px rgba(83, 111, 150, 0.3);
            background-color: $color_white;
            cursor: grabbing;
            min-height: 100px;
            background-color: #fff;  

            ._body {
              min-height: 50px;
              p {color: $ef-input-placeholder-color;}
            }

            &._repeater {
              background-color: #edf1f9;
              position: relative;
              margin-bottom: 10px;

              // shadow layers behind
              &:after,
              &:before {
                top: 10px;
                position: absolute;
                content: '';
                display: block;
                border-radius: 2px;
                box-shadow: 0 2px 4px 0 rgba(29, 55, 91, 0.1);
                border: dashed 1px rgba(83, 111, 150, 0.3);
              }  
              
              // shadow layers behind (first)
              &:after {
                bottom: -5px;
                left: 8px;
                right: 8px;
                z-index: -1;
                background-color: rgba(#edf1f9, 0.8);
              }  
              
              // shadow layers behind (second)
              &:before {
                bottom: -10px;
                left: 16px;
                right: 16px;
                z-index: -2;
                background-color: rgba(#edf1f9, 0.5);
              } 
            }
          }  
        }

        // blue shadow (common for sections and controls)
        &.gu-transit {
          width: auto;
          margin: 0 auto;
          margin-bottom: 8px;
          
          // hide bottom vertical line
          &:before {
            display: none;
          }
        }
      }
    
    // FORM CONTROL
    
      &._form_control {
        
        // dragged rotated element
        &.gu-mirror {
 
          // control styles
          .ef-drag-menu-item-component {
            border: dashed 1px rgba(83, 111, 150, 0.3);
            padding: 10px;
            background-color: $color_white;
            cursor: grabbing;
            min-width: 250px;
            background-color: #fff;  
          }  
        }

        // blue shadow (common for sections and controls)
        &.gu-transit {
          margin: 0;
          width: auto;
          margin: 0;
          margin-bottom: 8px;
          
          // hide bottom vertical line
          &:before {
            display: none;
          }
        }
      }
  }


// CHANGE ORDER 
  
  // (stages in workflow, controls and sections in form view)
  
  // STAGES IN WORKFLOW/PROCESS  

  .ef-stage-item {
    
    // blue shadow
    &.gu-transit {
      @include stage_item_drag_shadow;
      margin-right: auto;
      margin-left: auto;
    }
    
    // rotated dragged element
    &.gu-mirror {
      border: none;
      width: auto!important;
      
      // hide lines and indents
      ef-split-stage {
        ._inner {
          margin-top: 0;
          &:before {display: none;}
          &:after {display: none;}
          .ef-stage-card {
            &:before {display: none;}
          }
        }
      }
      
      // add new stage button
      ._add_new {
        display: none!important;
      }

      // for grabbing cursor
      &:after {
        content: '';
        position: absolute;
        top: 0; left: 0;right: 0;bottom: 0;
        display: block;
        cursor: grabbing;
        z-index: 1;
      }
    }
  }

  // SECTIONS IN CARD

  .ef-card-section-drag  {

    // dragged rotated element
    &.gu-mirror {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: none;
      background-color: transparent;
      height: 100px;
      
      // hide real section content
      .ef-card-section {
        display: none;
      }
      
      // show preview for rotated drag
      ._section_drag {
        transform: translateY(10px);
        display: flex!important;
        justify-content: flex-end;
        border: 1px dashed rgba(83, 111, 150, 0.3);
        align-items: center;
        min-width: 500px;
        height: 50px;
        background-color: #fff;
        position: relative;
        
        // top bg for cursor
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          cursor: grabbing;
        }

        ef-form-section-title {
          position: absolute;
          left: 10px;
          top: 10px;
          right: 150px;
        }

        .ef-form-section-settings {
          top: 10px;
          right: 10px;
        }
      }

      // styles for dragged repeater
      .ef-card-section {
        &._repeater + ._section_drag {
          background-color: #edf1f9;
        }
      }
    }

    // shadow
    &.gu-transit {
      width: 100%;
      overflow: hidden;
      margin-bottom: 8px; 
    }
  }

  // CONTROL IN SECTION

  .ef-form-control-moved {

    // ROTATE SIMPLE DRAGGED VIEW
    .ef-form-control-drag {
      display: none;
    }
    
    // rotated dragged element
    &.gu-mirror {
      background-color: transparent;
      border: none;
      
      // hide move control
      ._move_ctrl {display: none;}

      ef-form-control {
        margin-top: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        margin: 0;

        ._control {
          display: flex;
          justify-content: flex-end;
          
          // hide all real control (!!! - for global styles)
          >.js-ef-form-control-drag-hide {
            display: none;  
          }
          
          // show simple view for current element
          .ef-form-control-drag {
            position: relative;
            overflow: hidden;
            display: block;
            width: 273px;
            height: auto;
            border-radius: 2px;
            border: dashed 1px rgba(83, 111, 150, 0.3);
            background-color: #fcfdff;
            padding: 8px 16px;
            cursor: move!important;

            &:after {
              display: block;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              cursor: grabbing;
            }
          }
        }

        ef-form-control-options-toggle  {
          display: none;
        }
      }
    }
    
    // blue shadow
    &.gu-transit {
      width: 100%;
    }
  }








