// @import 'variables.scss';

@import 'variables';
@import 'mixins';
@import 'breakpoints';

html {
}

body {
  font-family: $ff-ns-regular;
  font-size: 16px;
  position: relative;
  background-color: $pale-grey;

  // for custom opened modal windows
  &.js-ef-body-disable-scroll {
    overflow: hidden;
  }
}

// !!! touch screens
body {
  // touch-action: manipulation;
  p {
    margin-bottom: 0;
  }
}

ef-hightlight-search-text {
  display: block;
  overflow: hidden;
  max-width: 100%;

  > ._inner {
    font-size: 14px;
    color: #536f96;
    line-height: 1.14;

    mark {
      color: #536f96;
    }
  }

  &._blue_text {
    > ._inner {
      mark {
        // line-height: 16px;
        color: $brand_blue;
        font-weight: bold;
      }
    }
  }

  &._yellow_bg {
    > ._inner {
      mark {
        // line-height: 16px;
        background-color: #fff252;
        font-weight: bold;
      }
    }
  }
}

// start app loader
#ef-app-loader {
  position: fixed;
  background-color: #fff;
  z-index: 10000000000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: $brand_blue;

  p {
    margin-left: 10px;
  }
}

// custom icons

.ef-icons {
  font-size: 16px;
  color: $action_icon_gray-4;
  transition: $action_icon-hover-transition;
}

.ef-icons-wrap {
  width: 26px;
  height: 26px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $action_icon-hover-transition;

  ._large {
    width: 32px;
    height: 32px;
  }

  &._add_bg {
    background-color: $action_icon_bg_color;
  }

  &:hover {
    .ef-icons {
      color: $action_icon_hover_color;
    }
    &._add_bg {
      background-color: $action_icon_bg_color-hover;
    }
  }
}

// flex boxes

// horizontal - space between
.ef-flex-sb-c {
  @include ef_flex_sb_c;
}

// horizontal - space around
.ef-flex-sa-c {
  @include ef_flex_sa_c;
}

// horizontal - flex start
.ef-flex-fs-c {
  @include ef_flex_fs_c;
}

// horizontal - flex end
.ef-flex-fe-c {
  @include ef_flex_fe_c;
}

// horizontal - center
.ef-flex-c-c {
  @include ef_flex_c_c;
}

// horizontal - space between. vertical - flex start
.ef-flex-sb-fs {
  @include ef_flex_sb_fs;
}

// direction - column, hor - center, vertical - start
.ef-flex-col-c-fs {
  @include ef_flex_col_c_fs;
}
// direction - column, hor - center, vertical - center
.ef-flex-col-c-c {
  @include ef_flex_col_c_c;
}

.ef-btn-disabled {
  @include ef_btn_disabled;
}

.ef-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// for drag drop elements in touchscreen
.ef-disable-scroll {
  overflow: hidden !important;
}

// for input fields

.ef-input-wrap {
  position: relative;
}

.ef-input-title {
  position: relative;
  display: block;
  margin-bottom: 5px;
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
  color: $brand_gray;

  &._required {
    padding-left: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '*';
      color: blue;
      font-size: 15px;
      opacity: 1;
    }
  }
}

// ERRORS for all inputs

.ef-input-error-wrap {
}

.ef-input-error-item {
  display: inline-block;
  color: red;

  &:first-letter {
    text-transform: uppercase;
  }

  padding: 10px 0px;
}

// for buttons in input (add, delete, icons etc)
.ef-input-inner {
  position: relative;

  ef-btn-add {
    position: absolute;
    top: 12px;
    right: 15px;
    padding: 0;
  }

  ef-select-stage-item {
    position: absolute;
    top: 12px;
    right: 15px;
  }
}

.ef-input-btn-add {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: $brand_gray;
  text-transform: uppercase;
  cursor: pointer;
}

.ef-drag-rotate {
  -moz-transform: rotate(-5deg); /* Для Firefox */
  -ms-transform: rotate(-5deg); /* Для IE */
  -webkit-transform: rotate(-5deg); /* Для Safari, Chrome, iOS */
  -o-transform: rotate(-5deg); /* Для Opera */
  transform: rotate(-5deg);
}

.ef-text-ellipsis {
  @include ef_text_ellipsis;
}

// small rotated triangle in select control
.ef-select-chevron {
  @include select_chevron;
}

// PAGE BEHAVIOUR - action and condition rows

.ef-page-behav-sub {
  @include ef_flex_sb_c;

  min-height: 48px;
  padding: 7px 8px;
  background-color: $ef-list-item-bg-color;
  box-shadow: inset 0 -1px 0 0 $ef-list-item-hover-bg-color;
}

// inputs wrapper
.ef-page-behav-sub-inputs {
  @include ef_flex_sb_c;

  align-items: flex-start;
  flex-grow: 1;
}

// item of input
.ef-page-behav-sub-item {
  padding-right: 8px;
}

// delete button
.ef-page-behav-sub-ctrls {
  align-items: flex-start;
  padding-left: 8px;
  padding-right: 5px;

  > button {
    .ef-icons-trashcan {
      font-size: 17px;
    }
  }
}

.ef-main-content {
  padding-top: $ef-app-header-height; // only padding! need for correct ngb tooltip
  padding-left: 0;
  transition: padding-left 0.2s linear;
}

.ef-main-content-title {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Nunito Sans Bold', sans-serif;
  color: $gray_ultra-dark;
  padding: 28px 24px 14px 24px;
}

// some logic block in any form
.ef-form-section {
  margin-top: 16px;
}

// bootstrap row without paddings
.row.ef-row-no-indents {
  margin-right: 0;
  margin-left: 0;

  & > [class^='col-'],
  & > [class*=' col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.ef-fluid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// grid for 5 columns in bootstrap 4 grid
.ef-grid-flex-5-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.ef-grid-flex-6-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
}
.ef-grid-flex-7-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 14.28%;
    max-width: 14.28%;
  }
}
.ef-grid-flex-8-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
.ef-grid-flex-9-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 11.11%;
    max-width: 11.11%;
  }
}
.ef-grid-flex-10-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 10%;
    max-width: 10%;
  }
}
.ef-grid-flex-11-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 9.09%;
    max-width: 9.09%;
  }
}
.ef-grid-flex-12-col {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
}
ngb-modal-backdrop {
  z-index: 1050 !important;
}
