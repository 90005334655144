@font-face {
  font-family: 'easiflow-icons';
  src:
    url('fonts/easiflow-icons.ttf?lezoi8') format('truetype'),
    url('fonts/easiflow-icons.woff?lezoi8') format('woff'),
    url('fonts/easiflow-icons.svg?lezoi8#easiflow-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ef-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'easiflow-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ef-icons-arrow-back:before {
  content: "\e94b";
}
.ef-icons-arrow-rollback:before {
  content: "\e94c";
}
.ef-icons-anchor:before {
  content: "\e924";
}
.ef-icons-action:before {
  content: "\e900";
}
.ef-icons-actions:before {
  content: "\e901";
}
.ef-icons-actions-link:before {
  content: "\e902";
}
.ef-icons-actions-set:before {
  content: "\e903";
}
.ef-icons-archive-default:before {
  content: "\e904";
}
.ef-icons-bell:before {
  content: "\e905";
}
.ef-icons-bookmark:before {
  content: "\e906";
}
.ef-icons-bookmark-selected:before {
  content: "\e907";
}
.ef-icons-calendar:before {
  content: "\e908";
}
.ef-icons-center-align-default:before {
  content: "\e909";
}
.ef-icons-check:before {
  content: "\e90a";
}
.ef-icons-check-circle:before {
  content: "\e90b";
}
.ef-icons-chevron-left:before {
  content: "\e90c";
}
.ef-icons-clone:before {
  content: "\e90d";
}
.ef-icons-close:before {
  content: "\e90e";
}
.ef-icons-close-1:before {
  content: "\e90f";
}
.ef-icons-close-circle:before {
  content: "\e910";
}
.ef-icons-collapse:before {
  content: "\e911";
}
.ef-icons-chevron-r:before {
  content: "\e912";
}
.ef-icons-chevron-l:before {
  content: "\e913";
}
.ef-icons-chevron-t:before {
  content: "\e914";
}
.ef-icons-chevron-b:before {
  content: "\e915";
}
.ef-icons-collapse-off:before {
  content: "\e916";
}
.ef-icons-copy:before {
  content: "\e917";
}
.ef-icons-dashboard-default:before {
  content: "\e918";
}
.ef-icons-data:before {
  content: "\e919";
}
.ef-icons-decline:before {
  content: "\e91a";
}
.ef-icons-drag:before {
  content: "\e91b";
}
.ef-icons-edit:before {
  content: "\e91c";
}
.ef-icons-edit-1:before {
  content: "\e91d";
}
.ef-icons-email-message:before {
  content: "\e91e";
}
.ef-icons-form-view:before {
  content: "\e91f";
}
.ef-icons-form-view-icon:before {
  content: "\e920";
}
.ef-icons-geolocation:before {
  content: "\e921";
}
.ef-icons-hamburger-menu:before {
  content: "\e922";
}
.ef-icons-high-priority:before {
  content: "\e923";
}
.ef-icons-image-center-align-default:before {
  content: "\e925";
}
.ef-icons-image-left-align-default:before {
  content: "\e926";
}
.ef-icons-image-right-align-default:before {
  content: "\e927";
}
.ef-icons-import-data:before {
  content: "\e928";
}
.ef-icons-launch:before {
  content: "\e929";
}
.ef-icons-left-align-default:before {
  content: "\e92a";
}
.ef-icons-letter:before {
  content: "\e92b";
}
.ef-icons-lock:before {
  content: "\e92c";
}
.ef-icons-logout:before {
  content: "\e92d";
}
.ef-icons-more:before {
  content: "\e92e";
}
.ef-icons-move:before {
  content: "\e92f";
}
.ef-icons-notification:before {
  content: "\e930";
}
.ef-icons-phone:before {
  content: "\e931";
}
.ef-icons-preview:before {
  content: "\e932";
}
.ef-icons-processes-default:before {
  content: "\e933";
}
.ef-icons-publish:before {
  content: "\e934";
}
.ef-icons-reassign:before {
  content: "\e935";
}
.ef-icons-right-align-default:before {
  content: "\e936";
}
.ef-icons-routes:before {
  content: "\e937";
}
.ef-icons-save:before {
  content: "\e938";
}
.ef-icons-save-1:before {
  content: "\e939";
}
.ef-icons-search:before {
  content: "\e93a";
}
.ef-icons-settings:before {
  content: "\e93b";
}
.ef-icons-settings-1:before {
  content: "\e93c";
}
.ef-icons-settings-2:before {
  content: "\e93d";
}
.ef-icons-share:before {
  content: "\e93e";
}
.ef-icons-stage-builder-view:before {
  content: "\e93f";
}
.ef-icons-stats-default:before {
  content: "\e940";
}
.ef-icons-task-open:before {
  content: "\e941";
}
.ef-icons-team-default:before {
  content: "\e942";
}
.ef-icons-timer:before {
  content: "\e943";
}
.ef-icons-trash:before {
  content: "\e944";
}
.ef-icons-trashcan:before {
  content: "\e945";
}
.ef-icons-undo-arrow:before {
  content: "\e946";
}
.ef-icons-users-cards:before {
  content: "\e947";
}
.ef-icons-users-list:before {
  content: "\e948";
}
.ef-icons-work-default:before {
  content: "\e949";
}
.ef-icons-workflow:before {
  content: "\e94a";
}