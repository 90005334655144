@import "variables";

.badge {
  font-size: 10px;
  padding: 6px 7px;
  color: $color_white;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }
}
.badge.badge-default {
  background-color: $brand_gray;
}

.badge.badge-hover {
  background-color: $brand_blue;
}
