

// flex

@mixin ef_flex_sb_c () {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin ef_flex_sa_c () {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin ef_flex_fs_c () {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin ef_flex_fe_c () {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin ef_flex_c_c () {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin ef_flex_sb_fs () {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin ef_flex_col_c_fs () {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@mixin ef_flex_col_c_c () {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


// text ellipsis
@mixin ef_text_ellipsis () {
  display: block;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}


// buttons
@mixin ef_btn_disabled () {
  background-color: #8b99a2;
  border-color: #8b99a2;
  cursor: default;

  &:hover {
    background-color: #8b99a2;
    border-color: #8b99a2;
    cursor: default;
  }
}

// dropdowns scrollbar

@mixin dropdown_scrollbar () {
  /*scroll for dropdown*/

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 15px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 1px 3px 0 $action_icon_gray-3;
    background-color: $brand_gray;
    border: solid 1px $action_icon_gray-6;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: $brand_gray;
  }
}

// ng-select small size 

@mixin ng_select_small_size () {
  height: $ef-select-height-small;
  
  // top container (with input/result)
  .ng-select-container {
    height: 30px;  
    min-height: 30px;

    .ng-value-container {

      // placeholder for closed dropdown
      .ng-placeholder {
        font-size: 14px;
      }

      // selected value
      .ng-value {

        .ng-value-label {
          font-size: 14px;
        }
      }
    }
  }

  .ng-dropdown-panel {
    &.ng-select-top {
      padding-bottom: 32px;

      &:before {
        bottom: 31px;
      }
    }
    &.ng-select-bottom {
      padding-top: 32px;

      &:before {
        top: 31px;
      }
    }
  } 

  &.ng-select-opened {

    &._error {
      border-color: transparent;

      .ng-select-container {
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}

// small rotated triangle in select control
  
  @mixin select_chevron () {
    width: 8px;
    height: 8px;
    border: none;
    display: inline-block;
    font-family: 'easiflow-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: 8px;
    box-sizing: border-box;
    transform: rotate(180deg);

    &:after {
      color: #2aa2fa;
      content: "\e914";
    }

    &._rotate {
      transform: rotate(0deg);
    }
  }

// small rotated triangle in select control
  
  @mixin set_rotate ($deg) {
    -moz-transform: rotate($deg); 
    -ms-transform: rotate($deg); 
    -webkit-transform: rotate($deg); 
    -o-transform: rotate($deg); 
    transform: rotate($deg);
  }

  