@import 'variables';



.dropdown-menu {
  padding: 0;
  margin: 0;
}

// !!! - only global. form view navigation top panel dropdown 
  
  bs-dropdown-container {
    z-index: $z-i-dropdowns; 
  }

  .navigation-dropdown {
    width: 100% !important;

    &.ui-state-default {
      border: none;
    }

    &.ui-state-focus {
      box-shadow: none;
    }

    &.ui-dropdown-open {
      & .ui-dropdown-trigger .object-rotate-down {
        transform: rotate(180deg);
        transition-duration: 0.5s;
      }
    }

    & .ui-dropdown-trigger {
      display: flex;
      align-items: center;
      border: none;
      font-size: 14px;
      color: $brand_blue-light;
    }


    &.ui-dropdown label.ui-dropdown-label {
      font-size: 16px;
      font-weight: bold;
      color: $brand_gray;
    }

    & .ui-dropdown-panel {
      width: 442px;
      height: 259px;
      margin-top: 10px;
      border-radius: 2px;
      padding: 50px 0 10px;
      box-sizing: border-box;
      background-color: $color_white;
      box-shadow: $ef-input-box-shadow-focus;

      &:before {
        position: absolute;
        content: 'All Stages';
        width: 96%;
        height: 46px;
        margin-left: 2%;
        padding-left: 10px;
        box-sizing: border-box;
        top: 0;
        border-bottom: 1px solid rgba(158, 178, 200, 0.3);
        display: flex;
        align-items: center;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: $brand_gray;
        text-transform: uppercase;
      }

      & .ui-dropdown-items.ui-dropdown-list {
        margin-left: 2%;
        padding: .6em;

        & li.ui-dropdown-item {
          padding: 0;
          background-color: transparent;
          font-size: 14px;
          line-height: 1.14;
          color: $brand_gray;
          margin: 16px 0;

          &.ui-dropdown-item-empty {
            text-indent: 0;
          }

          &.ui-state-disabled {
            color: $gray_disable;
            &:hover {
              color: $gray_disable;
            }
          }

          &:hover {
            color: $brand_blue-light;
          }

          &.ui-state-highlight {
            font-weight: bold;
          }
        }
      }
    }

    &:focus {
      outline: none;
    }
  }


