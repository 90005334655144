@import "variables";


// NGB-DATEPICKER
  
  $ngb-datepicker-border: solid 1px $action_icon_gray-6;
  
  ngb-datepicker {
    border: none;
    box-shadow: none;
    
    // all cells
    .bg-light {
      background-color: #fff!important;
    }
    
    // chevrons in header
    .ngb-dp-arrow-btn.btn.btn-link {
      color: rgba(120, 153, 200, 0.5);
    }
    
    .ngb-dp-header {
    }

    .ngb-dp-months {

    }

    .ngb-dp-months {

      .ngb-dp-month:first-child .ngb-dp-week {
        padding-left: 0;
      }

      .ngb-dp-month:last-child .ngb-dp-week {
        padding-right: 0;
      }

      .ngb-dp-week {
        padding: 0;  
      }

      &:first-child,
      &:last-child {
        .ngb-dp-week {
          padding: 0;  
        }
      }

      .ngb-dp-month>ngb-datepicker-month-view>.ngb-dp-week:last-child {
        padding: 0;
      }
      
      // week days
      .ngb-dp-weekdays { 
        border-bottom: $ngb-datepicker-border; 

        .ngb-dp-weekday {
          width: 41px;
          height: 32px;
          line-height: 32px;
          vertical-align: middle;
          opacity: 0.6;
          font-size: 14px;
          color: $brand_gray;
          text-align: left;
          font-style: normal;
        }    
      }



      // week (witowt week days)
      .ngb-dp-week {

        padding-left: 0;
        padding-right: 0;

        &:not(.ngb-dp-weekdays) {  
          border-radius: 0;
          border: $ngb-datepicker-border;
          border-top: none;
        }
      }

      // day
      .ngb-dp-day {
        width: 41px;
        height: 41px;
        
        // day inner
        .btn-light {
          width: 41px;
          height: 41px;
          line-height: 41px;
          width: 41px;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.6px;
          color: $brand_gray;
          border-radius: 0;

          &:hover {
            box-shadow: 0 3px 7px 0 rgba(100, 131, 167, 0.3);
            background-color: $dodger-blue;
            color: #fff;
          }
        }

        &:not(:last-child) {
          border-right: $ngb-datepicker-border;  
        }

        &.disabled {
          // opacity: 0.35;
          >* {
            opacity: 0.3;
          }
        }
      }

      .ngb-dp-today {
          background: yellow;
      }
    }
  }

  // range type 
  ngb-datepicker._is_range_type {
    .ngb-dp-month {
      // left month
      &:first-child {padding-right: 10px;}

      // right month
      &:last-child {padding-left: 10px;}
    }
    .ngb-dp-month+.ngb-dp-month>ngb-datepicker-month-view>.ngb-dp-week {
      padding-left: 0;
    }
  }