@import "variables";

// settings of form control (settings dropdown (settings, design, validation))
  
  // settings
  .ef-control-settings-slider-parent {
    
    .ef-control-settings-item-wrap {
      &:first-child {
        margin-top: 11px;
      }
    }

    ._slider_ctrls {
      margin-top: 16px;
      border-bottom: 1px solid $ef-border-color-light;
    }

    .ef-control-settings-radio-group {
      height: 40px;
      
      &:not(:last-child) {
        border-bottom: 1px solid $ef-border-color-light;
      }
    }
  }
  
  // design, validation
  .ef-control-settings-slider-child {
    padding-bottom: 24px;

    ef-slider-btn {
      border-top: none;
      border-bottom: 1px solid $ef-border-color-light; 
    }
  }

  // item in dropdown - settings, design, validation
  .ef-control-settings-item-wrap {
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
  }
