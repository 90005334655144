@import 'variables';

h1 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
}

h2 {
  @extend h1;
  font-size: 20px;
}

h3 {
  @extend h1;
  font-size: 16px;
  color: $brand_gray;
}

h2.title {
  font-weight: normal;
  letter-spacing: normal;
  color: $brand_gray;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: none;
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &:first-letter {
    text-transform: uppercase;
  }
}
