@import 'variables';
@import 'mixins';


// NG-SELECT. COMMON FOR SINGLE AND MULTIPLE SELECTION (exeption - .ef-select-stage-item)

  ng-select.ng-select.ef-ng-select {
    display: block;
    width: 100%;
    position: relative;
    border: $ef-input-border;
    height: $ef-select-height;
    border-radius: $ef-select-border-radius;
    background-color: #ffffff;

    // top container (with input/result)
    .ng-select-container {
      height: 100%;
      box-sizing: border-box;
      min-height: auto;
      box-shadow: $ef-select-box-shadow;
      border: none;
      background-color: #f1f5fd;
      cursor: pointer;

      .ng-value-container {
        height: 100%;
        position: relative;
        top: 0;
        padding-top: 0;
        padding-right: 5px;


        // placeholder for closed dropdown
        .ng-placeholder {
          top: 6px;
          padding: 0;
          white-space: nowrap;
          overflow:hidden;
          font-size: 16px;
          text-overflow: ellipsis;
          color: $ef-input-placeholder-color;

          &:first-letter {
            text-transform: uppercase;
          }
        }

        // selected value
        .ng-value {

          .ng-value-label {
            display: inline-block;
            font-size: 16px;
            line-height: 1.14;
            color: $brand_gray;
            &:first-letter {text-transform: uppercase;}
          }
        }

        // search
        .ng-input {
          height: 100%;
          top: 0;
          padding-right: 10px;

          input {
            height: 100%;
            font-size: 14px;
            color: $brand_gray;
            caret-color: #2aa2fa;
            padding: 0 8px 0 12px;
            border-radius: 3px;
          }
        }
      }

      // rotated shevron
      .ng-arrow-wrapper {
        width: 20px;
        height: 8px;
        padding-right: 0;
        right: 0px;

        .ng-arrow {
          @include select_chevron;

          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    // OPENED DROPDOWN LIST
    .ng-dropdown-panel {
      z-index: -1;
      top: 0;
      padding-top: $ef-input-height;
      border-radius: 2px;
      margin-bottom: 10px;
      box-shadow: $ef-input-box-shadow-focus;
      border: none;

      .ng-dropdown-panel-items {
        @include dropdown_scrollbar;
        margin-bottom: 0;

        // opened dropdown item (option)
        .ng-option,
        .ng-option.ng-option-selected,
        .ng-option.ng-option-marked {
          height: $ef-input-height;
          padding: 0;
          line-height: $ef-input-height;
          font-size: 16px;
          padding: 0 12px;
          background-color: #fff;

          .ng-option-label {
            display: inline-block;
            font-size: 16px;
            color: $gray_ultra-dark;
            &:first-letter {text-transform: uppercase;}
          }

          &:hover {
            background-color: $dodger-blue;
            .ng-option-label {
              color: #fff;
            }
          }
        }
      }

      // divider (line) between opened list and selected value
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        opacity: 0.3;
        background-color: #9eb2c8;
        left: 8px;
        right: 8px;
        top: 39px;
      }

      // show shadow for dropdown
      &.ng-select-top {
        bottom: auto;
      }

      // TOP POSITION
      &.ng-select-top {
        top: auto;
        bottom: 0;
        padding-top: 0;
        padding-bottom: $ef-input-height;
        margin-bottom: 0;

        // divider (line) between opened list and selected value
        &:before {
          top: auto;
          left: 8px;
          right: 8px;
          bottom: 39px;
        }
      }
    }

    // STATE - OPENED

    &.ng-select-opened {
      z-index: 1;
      box-shadow: none;
      border: 1px solid transparent;

      .ng-select-container {
        box-shadow: none;
        border-color: transparent;

        .ng-value-container {
          // search
          .ng-input {
            input {
              background-color: rgba(255,255,255,.6);
            }
          }
        }
      }

      .ng-arrow-wrapper {
        margin-top: 0;
        .ng-arrow {
          top: 2px;
          left: -2px;
          transform: rotate(0deg);
        }
      }
    }

    // STATE - FOCUSED (focused but not opened)

    &.ng-select-focused:not(.ng-select-opened) {
      .ng-select-container {
        box-shadow: $ef-select-box-shadow;
        // border: $ef-select-border;
      }
    }

    // STATE - DISABLED

    &.ng-select-disabled {

      background-color: $ef-input-disabled-bg;
      border: $ef-input-disabled-border;

      .ng-select-container {

        background-color: $ef-input-disabled-bg;
        cursor: default;

        // selected value
        .ng-value {
          .ng-value-label {
            color: $ef-input-disabled-text-color;
          }
        }

        // rotated shevron
        .ng-arrow-wrapper {
          .ng-arrow {
            &:after {
              color: $ef-input-disabled-border-color;
            }
          }
        }
      }
    }

    // HOVER
    &:not(.ng-select-disabled) {
      &:not(.ng-select-opened) {
        &:hover {
          border: $ef-input-hover-border;
        }
      }
    }
  }

// SPECIAL STYLES FOR MULTIPLE

  ng-select.ng-select.ng-select-multiple.ef-ng-select  {

    // top container (with input/result)
    .ng-select-container {

      // rotated shevron
      .ng-arrow-wrapper {
        margin-top: 0;
        .ng-arrow {
          // white triangle creates chevron
          &:after {
          }
        }
      }
    }

    // STATE - OPENED

    &.ng-select-opened {

      .ng-arrow-wrapper {
        margin-top: -9px;
        .ng-arrow {}
      }
    }
  }

// ERROR (error form control- required ....)

  ng-select.ng-select.ef-ng-select {
    &:not(.ng-select-opened) {
      &._error {
        border-color: $ef-input-error-border-color;
        .ng-select-container {
          box-shadow: none;
          background-color: $ef-input-error-bg-color;
        }
      }
    }
  }

// SIZE - SMALL

  // ng-select.ng-select.ef-ng-select  {
  //   height: $ef-select-height-small;

  //   @include ng_select_small_size;
  // }

// TYPE - NO BORDERS (in selected status)

  ng-select._no_borders.ng-select.ng-select-single.ef-ng-select  {
    background-color: transparent;

    &.ng-select-focused:not(.ng-select-opened),
    &:not(.ng-select-focused) {

      .ng-select-container {
        border: none;
        box-shadow: none;
      }
    }
  }
