
.form-builder-container {
	top: 0!important;
}

.dragg {
	width: 100px;
	height: 100px;
	background-color: #ccc;
}

.dnd-poly-drag-image {
	position: fixed!important;
	width: 100px!important;
	height: 100px!important;
	opacity: 0.5!important;
	max-width: 100px!important;
	max-height: 100px!important;
}

.test-placeholder {
	width: 100%;
	height: 50px;
	background-color: blue;
}