@import 'variables';

// ngb-tooltip
// !!! important - if it tooltip used in modal window it not use global config
// global config fot tooltip set in app.component.ts

.tooltip {
  .tooltip-inner {
    background-color: $ef-tooltip-bg-color;
    opacity: $ef-tooltip-opacity;
    border-radius: 3px;
    font-size: 12px;
  }
  .arrow {
    opacity: $ef-tooltip-opacity;
    &:before {
      border-color: transparent;
    }
  }
  
  // left position
  &.bs-tooltip-left {
    .arrow {
      &:before {
        border-left-color: $ef-tooltip-bg-color;
      }
    }
  }
  
  // bottom  position
  &.bs-tooltip-bottom {
    .arrow {
      &:before {
        border-bottom-color: $ef-tooltip-bg-color;
      }
    }
  }

  // right position
  &.bs-tooltip-right {
    .arrow {
      &:before {
        border-right-color: $ef-tooltip-bg-color;
      }
    }
  }

  // top position
  &.bs-tooltip-top {
    .arrow {
      &:before {
        border-top-color: $ef-tooltip-bg-color;
      }
    }
  }
}
