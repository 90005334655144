@import "variables";

// reset bootstrap
.input-group {
  .form-control {
    &:focus {
      z-index: auto;
    }
  }
}

.ef-input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  border-radius: $ef-input-radius;
  box-sizing: border-box;
  box-shadow: $ef-input-box-shadow;
  border: $ef-input-border;
  color: $ef-input-text-color;
  background-color: $ef-input-bg-color;
  outline: none;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;

  &::placeholder {
    color: $ef-input-placeholder-color;
    &::first-letter {text-transform: uppercase;}
  }

  &:focus{
    box-shadow: $ef-input-focus-shadow;
    outline: none;
  }

  &:disabled {
    border: $ef-input-disabled-border;
    color: $ef-input-disabled-text-color;
    box-shadow: $ef-input-disabled-shadow;
    background-color: $ef-input-disabled-bg;
  }

  &:hover {
    &:not(:disabled) {
      &:not(:focus) {
        border: $ef-input-hover-border;
      }
    }
  }

  &._small {
    height: $ef-input-height-small;
    font-size: 14px;
  }
}

textarea.ef-input {
  min-height: 80px;
  padding-top: 8px;
  padding-bottom: 8px;
}


// input with right button
.ef-input-btn {
  width: 100%;
  display: flex;

  input {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 3px 0 0 3px;
    border: $ef-input-border;
    background-color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: $brand_gray;
    flex-grow: 1;
  }

  button {
    height: 40px;
    border: $ef-input-border;
    border-left: none;
    padding: 0 16px;
    text-transform: uppercase;
    border-radius: 0 3px 3px 0;
    box-shadow: 0 1px 3px 0 rgba(100, 131, 167, 0.3);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: $brand_gray;
  }
}

// PREFIX, SUFFIX

  .ef-input-group {
    display: flex;

    ef-input {
      flex-grow: 1;

      .ef-input {
        border: solid 1px $action_icon_gray-6;
        background-color: #fff;
      }
    }

    &.ef-input-group-prefix {
      .ef-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ef-input-group-suffix {
      ef-input {

        .ef-input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .ef-input-prefix,
  .ef-input-suffix {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $ef-input-radius;
    box-sizing: border-box;
    box-shadow: $ef-input-group-suf-pref-shadow;
    border: solid 1px $action_icon_gray-6;
    color: $brand_gray;
    font-size: 16px;
    line-height: 1;
    background-color: $ef-input-group-suf-pref-bg-color;
    outline: none;
  }

  .ef-input-prefix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .ef-input-suffix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
