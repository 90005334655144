@import 'variables';
@import 'mixins';

// click elem

  .ef-radio-simple {
    position: relative;
    height: 100%;
  }

// circle

  .ef-radio-simple-checkmark {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: -1px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: solid 1px rgba(139, 153, 162, 0.5);
    background-color: rgba(139, 153, 162, 0.2);
  }

// title

  .ef-radio-simple-title {
    opacity: 0.6;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #8b99a2;
    text-transform: uppercase;
  }

// state - active

  .ef-radio-simple {

    &._active {

      .ef-radio-simple-checkmark { box-shadow: none; }

      &._green {
        .ef-radio-simple-checkmark {
          background-color: #7ed321;
          border-color: #7ed321;
        }
      }

      &._orange {
        .ef-radio-simple-checkmark {
          background-color: #f5a623;
          border-color: #f5a623;
        }
      }

      &._red {
        .ef-radio-simple-checkmark {
          background-color: #f05c3e;
          border-color: #f05c3e;
        }
      }

      &._blue {
        .ef-radio-simple-checkmark {
          background-color: $brand_blue;
          border-color: $brand_blue;
        }
      }
    }
  }

