
@import "variables";

// widget header controls (drag, collapse, delete, settings)
.ef-widget-header-ctrl-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	cursor: pointer;
	transition: 0.2s;

	.ef-icons {
		color: $action_icon_gray;
	}

	&._rotate {
		.ef-icons {
			transform: rotate(180deg);
		}  
    }

	&:hover {
		.ef-icons {
			color: #fff;
		}
	}	
}

// drag widget
.ef-widget-drag {
	
	// blue shadow 
	&.gu-transit {
		margin-top: 25px;
		height: $ef-dashb-widget-header-height;

		._drag_wrap {

		}
	}

	// dragget element
	&.gu-mirror {
		padding: 0;
		overflow: hidden;
		height: $ef-dashb-widget-header-height;

		ef-widget {
			margin-top: 0;
		}
	}
}
