@import "variables";

// suffix "-r" - is redesign

// COMMON FOR ALL TYPES

  .ef-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: $ef-btn-font-size;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: $brand_gray;
    cursor: pointer;
    text-transform: uppercase;
    transition: all .2s linear;
  }

  .ef-btn {
    .ef-icons {
      color: $brand_gray;
      margin-right: 8px;
    }
  }


// TYPES ( default | primary | danger | link | with icon )

  .ef-btn {

    &.ef-btn-default,
    &.ef-btn-primary,
    &.ef-btn-danger {
      padding: 0 20px;
      border-radius: $ef-btn-radius;
      height: $ef-btn-height;
    }

    // TYPE - DEFAULT WITH BORDERS AND WITHOUT BACKGROUND

    &.ef-btn-default {
      background-color: #fff;
      box-shadow: 0 1px 3px 0 $action_icon_gray-3;
      border: $ef-btn-default-border;
      &.file-selector {
        top: -55px;
        background-color: rgba(83, 111, 150, 0.7);
        font-size: 12px;
        color: $zircon;
      }

      &:active {
        box-shadow: inset 0 1px 3px 0 rgba(83, 111, 150, 0.3);
        background-color: $pale-grey;
        border: solid 1px $action_icon_gray-6;
      }
    }

    // TYPE - PRIMARY WITH BORDERS AND BACKGROUND

    &.ef-btn-primary {
      background-color: $brand_blue;
      color: $color_white;

      &:active {
        background-color: $brand_blue;
        box-shadow: inset 0 1px 2px 0 rgba(83, 111, 150, 0.5);
      }
    }

    // TYPE - DANGER WITH BORDERS AND BACKGROUND

    &.ef-btn-danger {
      color: $color_white;
      background-color: $danger;
      box-shadow: 0 2px 4px 0 $action_icon_gray-3;
    }

    // TYPE - LINK

    &.ef-btn-link {
      i {margin-right: 5px;}
    }

    // TYPE - WITH ICON AND TEXT

    &.ef-btn-with-icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $brand_gray;

      &:not(.ef-btn-link) {
        padding: 0 15px 0 11px;
      }

      // icon
      >.ef-icons,
      >i {
        display: flex;
        font-size: 16px;
        margin-right: 9px;
      }

      // small
      &.ef-btn-small {
        padding: 0 12px 0 9px;
        i,span {

          margin-right: 9px;
        }
      }

      // large
      &.ef-btn-large {
        padding: 0 20px;
        i, span {margin-right: 15px;}
      }

      &.ef-btn-primary,
      &.ef-btn-danger {
        color: #fff;
      }
    }

    // '-r' - redesign
    &.ef-btn-with-icon-r {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 13px;
      border-radius: 3px;
      box-shadow: 0 2px 8px 0 rgba(100, 131, 167, 0.1);
      border: none;

      // icon
      >.ef-icons,
      >i {
        display: flex;
        color: #fff;
        margin-right: 8px;
      }

      span {
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }

      &.ef-btn-default {

        // icon
        >.ef-icons,
        >i {
          color: $brand_blue;
        }

        span {
          color: $brand_gray;
        }
      }

      &.ef-btn-primary {

      }

      &.ef-btn-danger {

      }

      &:hover {

        // icon
        >.ef-icons,
        >i {
          color: #fff;
        }

        span {
          color: #fff;
        }
      }
    }
  }

  // BUTTON ONLY WITH ICON
  .ef-btn-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    color: $action_icon_gray-3;
  }

// SIZES

  .ef-btn {

    // with borders and background

    &.ef-btn-default,
    &.ef-btn-primary,
    &.ef-btn-danger {
      border-radius: $ef-btn-radius;
      height: $ef-btn-height;
      line-height: 15px;

      // small
      &.ef-btn-small {
        height: $ef-btn-height-small;
        line-height: $ef-btn-height-small;
        border-radius: $ef-btn-radius-small;
        font-size: $ef-btn-font-size-small;
      }

      // large
      &.ef-btn-large {
        height: $ef-btn-height-large;
        line-height: $ef-btn-height-large;
        border-radius: $ef-btn-radius-large;
        font-size: $ef-btn-font-size-large;
      }
    }

    // link type

    &.ef-btn-link {


      // small
      &.ef-btn-small {
        font-size: $ef-btn-font-size-small;
      }

      // large
      &.ef-btn-large {
        font-size: $ef-btn-font-size-large;
      }

      // color - danger
      &.ef-btn-link-danger {
        color: $danger-dark;
        font-size: 12px;
        text-transform: uppercase;

        &:disabled {
          color: $gray_light2;
        }
      }
    }
  }

// STATE - HOVER

  // TYPES ( default | primary | danger | link | with icon )

  .ef-btn:hover {
    text-decoration: none;

    &:not(:disabled) {
      .ef-icons {
        color: #fff;
      }
    }
  }

  .ef-btn:not(:disabled) {

    // TYPE - DEFAULT WITH BORDERS AND WITHOUT BACKGROUND
    &.ef-btn-default {
      &:hover {
        box-shadow: 0 3px 7px 0 $action_icon_gray-3;
        background-color: $dodger-blue;
        color: $color_white;
        border-color: $brand_blue-light;
      }
    }

    // TYPE - PRIMARY WITH BORDERS AND BACKGROUND
    &.ef-btn-primary {
      &:hover {
        background-color: $brand_blue-light;
        box-shadow: 0 3px 7px 0 $action_icon_gray-3;
      }
    }

    // TYPE - DANGER WITH BORDERS AND BACKGROUND
    &.ef-btn-danger {
      &:hover {
        color: $color_white;
        background-color: $danger-light;
        box-shadow: 0 2px 4px 0 rgba(208, 2, 27, 0.42);
      }
    }

    // TYPE - LINK
    &.ef-btn-link {
      &:hover {
        color: $dodger-blue;
        i {color: $dodger-blue;}
      }
    }
  }


// STATE - ACTIVE

  .ef-btn {

    // TYPE - DEFAULT WITH BORDERS AND WITHOUT BACKGROUND
    &.ef-btn-default {
      &:active {
        box-shadow: inset 0 1px 3px 0 rgba(83, 111, 150, 0.3);
        background-color: $pale-grey;
        border: solid 1px $action_icon_gray-6;
      }
    }

    // TYPE - PRIMARY WITH BORDERS AND BACKGROUND
    &.ef-btn-primary {
      &:active {
        background-color: $brand_blue;
        box-shadow: inset 0 1px 2px 0 rgba(83, 111, 150, 0.5);
      }
    }

    // TYPE - DANGER WITH BORDERS AND BACKGROUND
    &.ef-btn-danger {
      &:active {

      }
    }

    // TYPE - LINK
    &.ef-btn-link {
      &:active {}
    }
  }

// STATE - DISABLED

  .ef-btn {
    &:disabled {
      cursor: default;
      box-shadow: none;

      // default
      &.ef-btn-default {
        border-color: rgba(139, 153, 162, 0.5);
        color: $ef-border-color-light;
        i {
          color: rgba(139, 153, 162, 0.5);
        }
      }

      // primary, danger
      &.ef-btn-primary,
      &.ef-btn-danger {
        background-color: $gray_disable;
        border-color: $gray_disable;
      }

      // link
      &.ef-btn-link {
        color: $ef-input-disabled-text-color;
        i {color: $ef-input-disabled-text-color;}
      }
    }
  }

// button with icon 'more' with hover bg
  .ef-btn-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    .ef-icons-more, {
      color: $action_icon_gray;
      font-size: 11px;
    }

    &:hover {
      background-color: $gray_light;

      .ef-icons-more  {
        color: $dodger-blue;
      }
    }
  }
