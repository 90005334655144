// used in permission and section component

.ef-card-section {
  position: relative;
  padding: 4px 8px 8px 8px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(83, 111, 150, 0.1);
}

.ef-control-settings-radio-group {
  height: 40px;
}

// CUSTOMIZE CLASSES FOR FORM BUILDER

.ef-h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color: $dark-grey-blue;
}

.ef-h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: $gray_ultra-dark;
}

.ef-h3 {
  font-size: 18px;
  line-height: 1.33;
  color: $gray_ultra-dark;
}

.ef-h4 {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $gray_ultra-dark;
}

.ef-h5 {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
  color: $ship-cove;
}

.ef-h6 {
  font-size: 10px;
  line-height: 2.4;
  text-transform: uppercase;
  color: $ship-cove;
}

// text decoration
.ef-underline {
  text-decoration: underline;
}
.ef-line-through {
  text-decoration: line-through;
}
.ef-bold {
  font-weight: bold;
}
.ef-italic {
  font-style: italic;
}

// paddings
.ef-form-cont-small-padding {
  padding: 0px;
}
.ef-form-cont-medium-padding {
  padding: 10px;
}
.ef-form-cont-large-padding {
  padding: 20px;
}

// margins
.ef-form-cont-small-margin {
  margin: 0px;
}
.ef-form-cont-medium-margin {
  margin: 10px;
}
.ef-form-cont-large-margin {
  margin: 20px;
}

// font size
.ef-form-cont-small-font-size {
  font-size: 12px;
}
.ef-form-cont-medium-font-size {
  font-size: 16px;
}
.ef-form-cont-large-font-size {
  font-size: 24px;
}

// section layout

$ef-drop-zone-height: 68px; // need for center position of blue shadow

.ef-stage-section-layout {
  min-height: $ef-drop-zone-height;
  height: 100%;
  border-radius: 3px;
  padding-left: 5px;

  // drag and drop title
  > p {
    text-align: center;
    font-size: 16px;
    padding: 0 8px;
    color: $ef-input-placeholder-color;
  }

  // if element over
  &._drag {
    background-color: rgba(#2aa2fa, 0.1); //#f1f5fd;
  }

  &._forbidden {
    border-color: $danger-light;
    border-style: solid;
    box-shadow: inset 0px 0px 0px 1px $danger-light;
    position: relative;

    > * {
      display: none !important;
    }
  }
}

// form  section settings
.ef-form-section-settings {
  width: $ef-section-controls-width;
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;

  // item of settings
  .ef-form-section-settings-item {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    transition: color 0.2s linear;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &._disabled,
    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled) {
      &:hover,
      &._active {
        color: $brand_blue;
        border-color: $brand_blue;
      }
    }

    > i {
      color: $action_icon_gray-3;
    }

    &:focus {
      outline: none;
    }

    &._move {
      cursor: grab;
    }
  }

  .dropdown-menu {
    z-index: 2;
    position: absolute;
    top: 100%;
    right: 0 !important;
    left: auto !important;
    box-shadow: 0 2px 4px 0 rgba(85, 110, 152, 0.3);
    margin-bottom: 20px;

    app-section-settings {
      display: block;
    }
  }
}
