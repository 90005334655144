@import "variables";

// COMMON FOR ALL WINDOWS

  ngb-modal-window.modal {
    z-index: $z-i-modals;
  }

  // modal wrapper

  .modal-dialog {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
  }

  // content

  .modal-content {
    position: relative;
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 2px;
    background-color: #ffffff;
    border: none;
    box-shadow: $ef-input-box-shadow-focus;
  }



  // close btn
  .ef-modal-close {
    position: absolute;
    right: 17px;
    top: 17px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: $brand_gray;

    &:hover {
      color: $brand_blue;
    }
  }

  .ef-modal-header,
  .ef-modal-footer {
    background-color: $pale-grey;
  }

  .ef-modal-header {
    border-radius: 3px 3px 0 0;
  }

  .ef-modal-body {
    padding: 20px 40px;
  }

  .ef-modal-footer {
    border-radius: 0 0 3px 3px;
  }

  .ef-modal-footer {
    .ef-btn {
      margin-right: 16px;
    }
  }

  // header

  .modal-header {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    padding: 0;
    & .modal-title {
      color: #D0021B;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
    }
  }

  // body

  .modal-body {
    padding: 20px 0 10px;
  }

  // footer

  .modal-footer {
    justify-content: flex-start;
    padding: 0;
    border-top: none;
  }

  // modal background

  .modal-backdrop {
    background-color: rgba(83, 111, 150, 0.7);
  }

  // center position

  .ef-modal-position-center {
    display: flex!important;
    justify-content: center;
    align-items: center;

    .modal-dialog {
      margin: auto;
    }
  }

// INSTANCES

  .ef-modal-create-category,
  .ef-modal-delete-folder,
  .ef-modal-move-folder,
  .ef-modal-edit-folder,
  .ef-modal-reassign-process {

    .modal-content {
      max-width: 400px;
    }
  }

  // folder create
  .ef-modal-create-category {

    .modal-content {
    }
  }

  // process
  .ef-modal-reassign-process {

    .modal-content {
      padding: 30px 40px;
    }
  }


  // modal - action modal for control

  .ef-modal-control-action {
    .modal-content {
    }
  }

  // modal - stage settings

  .ef-modal-stage-settings {

    .modal-dialog {
      transform: none!important;
      top: 0;
      width: 90%;
      max-width: 740px;
    }

    .modal-content {
      width: 100%;
      padding: 0;
    }
  }

  // search global

  .ef-modal-search-global {

    .modal-dialog {
      transform: none!important;
      top: 0;
      width: 90%;
      max-width: 704px;

    }

    .modal-content {
      width: 100%;
      padding: 0;
    }
  }

  .ef-modal-reassign-process {

  }
