
@import "variables";

.ef-search-global-view {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ef-search-global-filters {
  flex-basis: 30%;
  min-width: 30%;
  order: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ef-search-global-filter-selector {
    &:nth-child(2) {
      display: block;
      margin-top: 40px;
    }
  }
}

.ef-search-global-results {
  flex-basis: 70%;
  min-width: 70%;
  order: 1;
  padding-right: 30px;

  >p {
    font-size: 14px;
    padding-bottom: 17px;
    color: rgba($brand_gray, 0.6);
  }
}
