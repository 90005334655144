
@import "variables";

// FORM CONTROLS

// COMMON STYLES FOR CHECKBOX AND RADIO (pretty type)
  
  .ef-check-radio {
    cursor: pointer;
    padding: 8px;
    max-width: 100%;
  }

  // checkmark background

    .ef-check-radio-checkmark {
      position: relative;
      display: inline-block;
      border-radius: 3px;
      width: 24px;
      height: 24px;
      box-shadow: inset 0 1px 3px 0 rgba(83, 111, 150, 0.3);
      border: solid 1px $action_icon_gray-6;
      
      // checkmark mark

      &:after {
        display: none;
        content: '';
        position: absolute;
      }
    }

  // title
  
    .ef-check-radio-title {
      display: inline-block;
      margin-left: 8px;
      font-size: 16px;
      line-height: 1;
      letter-spacing: normal;
      color: $brand_gray;

      &:first-letter {
        text-transform: uppercase;
      }
    }

  // STATES

    // checked
    
      .ef-check-radio {

        &._active {
          .ef-check-radio-checkmark {
            background-color: $brand_blue;
            border-color: $brand_blue;
            &:after {
              display: block;
            }
          }  
        }
      }

    // disabled

      .ef-check-radio {

        &:disabled {
          cursor: default;

          &:not(._active) {
            .ef-check-radio-checkmark {
              border-color: $ef-input-disabled-border-color;
              box-shadow: none;
              background-color: $ef-input-disabled-bg;
            }

            .ef-check-radio-title {
              color: #a0a0a0;
            }  
          }

          &._active {

            .ef-check-radio-checkmark {
              border-color: $ef-input-disabled-text-color;//$ef-input-disabled-border-color;;
              background-color: $ef-input-disabled-text-color;  
              box-shadow: none;
            }

            .ef-check-radio-title {
              color: #a0a0a0;
            }

            &._radio {
              .ef-check-radio-checkmark {
                &:after {
                  background-color: rgba(255, 255, 255, 0.6); // for radio
                }
              }
            }  

            &._checkbox {
              .ef-check-radio-checkmark {
                &:after {
                  border-color: rgba(255, 255, 255, 0.6); // for checkbox
                }
              }
            }  
          }
        }
      }

    // hover
  
      .ef-check-radio {

        &:hover {}
      }


// TYPES 
  
  //  CHECKBOX
    
    .ef-check-radio._checkbox {
      
      // checkmark

      .ef-check-radio-checkmark {
        
        &:after {
          left: 7px;
          top: 2px;
          width: 8px;
          height: 14px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }


  // RADIO
  
    .ef-check-radio._radio {

      // checkmark

      .ef-check-radio-checkmark {
        border-radius: 50%;

        &:after {
          top: 6px;
          left: 6px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }

// SIZES (small, medium, large)
  
  // main styles

    @mixin create_size_type ($checkmarkSize, $titleSize) {
      .ef-check-radio {
        .ef-check-radio-checkmark {
          width: $checkmarkSize;
          height: $checkmarkSize;
          min-width: $checkmarkSize;
          min-height: $checkmarkSize;;
        }  
        .ef-check-radio-title {
          font-size: $titleSize;
        }
      }  
    }
    ef-checkbox,
    ef-radio {
      &.ef-form-cont-small-font-size { @include create_size_type(16px, 14px);}
      &.ef-form-cont-medium-font-size { @include create_size_type(24px, 16px);}
      &.ef-form-cont-large-font-size { @include create_size_type(32px, 24px);}
    }

  // marks

    @mixin create_size_type_mark ($elemType, $markTop, $markLeft, $markWidth, $markHeight) {
      .ef-check-radio {
        .ef-check-radio-checkmark {
          &:after {
            top: $markTop;
            left: $markLeft;
            width: $markWidth;
            height: $markHeight;
            @if $elemType == 'checkbox-small' {
              border-width: 0 2px 2px 0;
            } 
            @if $elemType == 'checkbox-large' {
              border-width: 0 4px 4px 0;
            } 
          }
        }  
      }  
    }
    ef-checkbox {
      &.ef-form-cont-small-font-size { @include create_size_type_mark('checkbox-small', 1px, 4px, 6px, 10px);        }
      &.ef-form-cont-medium-font-size { @include create_size_type_mark('checkbox-medium', 2px, 7px, 8px, 14px);}
      &.ef-form-cont-large-font-size { @include create_size_type_mark('checkbox-large', 2px, 10px, 11px, 19px);}
    }
    ef-radio {
      &.ef-form-cont-small-font-size { @include create_size_type_mark('radio-small', 4px, 4px, 6px, 6px);        }
      &.ef-form-cont-medium-font-size { @include create_size_type_mark('radio-medium', 6px, 6px, 10px, 10px);}
      &.ef-form-cont-large-font-size { @include create_size_type_mark('radio-large', 8px, 8px, 14px, 14px);}
    }
  

  // PADDINGS 

    ef-checkbox,
    ef-radio {
      &._no_padding {
        padding: 0;
        .ef-check-radio {padding: 0;} 
      }
      &.ef-form-cont-small-padding {.ef-check-radio {padding: 0;} }
      &.ef-form-cont-medium-padding {.ef-check-radio {padding: 0;} }
      &.ef-form-cont-large-padding {.ef-check-radio {padding: 0;} }
    }

// COLORS
  
  // dodger blue
  ef-checkbox,
  ef-radio {
    
    &._dodger_blue {

      .ef-check-radio {

        &._active {
          
          .ef-check-radio-checkmark {
            background-color: $dodger-blue;
            border-color: $dodger-blue;
            box-shadow: 0 3px 7px 0 rgba(100, 131, 167, 0.3);
          }  
        }
      }
    }
  }



