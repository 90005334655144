@import 'variables';

.ef-tabs {
  background-color: $pale-grey;

  &-header {
    display: flex;
    background-color: $pale-grey;
    justify-content: flex-start;
    padding-left: 20px;
    overflow: hidden;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 2px solid transparent;
      padding: 0 18px;
      height: 40px;
      font-size: 10px;
      font-weight: 600;
      line-height: 1.6;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      cursor: pointer;
      color: $brand_gray;

      &-title {
        @include ef_text_ellipsis;
        max-width: 140px;
        font-family: 'Nunito Sans Semi Bold', sans-serif;
      }

      &._active {
        background-color: $color_white;
        border-top: 1px solid $brand_blue;
        box-shadow: inset 0 2px 0 0 $brand_blue;

        &:disabled {
          border-top-color: rgba(9, 38, 71, 0.3);
        }
      }

      &:focus,
      &:active {
        outline: none;
      }

      &:disabled {
        cursor: default;
        color: $ef-input-disabled-text-color;
      }
    }
  }

  &-body {
    background-color: $color_white;
    padding: 50px 20px;
    min-height: 80vh;
    height: auto;

    &-item {
      padding: 0;
    }
  }

  @media (max-width: 450px) {
    &-header {
      width: 80%;
      padding-left: 15px;
      overflow-x: scroll;

      &-item {
      }
    }

    &-body {
      padding: 10px;

      &-item {
      }
    }
  }
}
