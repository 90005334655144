@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

$fonts-path: '/assets/fonts/';

// nunito sans
@font-face {
	font-family: 'Nunito Sans Regular';
	src: url('#{$fonts-path}NunitoSans-Regular.ttf') format('truetype');
	font-style: normal;
}
@font-face {
	font-family: 'Nunito Sans Semi Bold';
	src: url('#{$fonts-path}NunitoSans-SemiBold.ttf') format('truetype');
	font-style: normal;
}
@font-face {
	font-family: 'Nunito Sans Bold';
	src: url('#{$fonts-path}NunitoSans-Bold.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans Extra Bold';
	src: url('#{$fonts-path}NunitoSans-ExtraBold.ttf') format('truetype');
	font-style: normal;
}

// space mono
@font-face {
	font-family: 'Space Mono Bold';
	src: url('#{$fonts-path}SpaceMono-Bold.ttf') format('truetype');
	font-style: normal;
}

