//colors

	// blue
	$brand_blue: #2aa2fa;
	$brand_blue-light: #38c2ff;
	$dodger-blue: #38c2ff;

	// gray
	$brand_gray: #536f96;
	$brand_gray-6: rgba($brand_gray, 0.6);
	$brand_gray-8: rgba($brand_gray, 0.8);
	$gray_settings: #7899c8;
	$gray_light2: #d2dbe5;
	$gray_ultra-dark: #3e5473;
	$gray_disable: #8b99a2;
	$gray_light: #edf1f9;

	// grey
	$pale-grey: #eff2f8;
	$cool-grey: #8b99a2;

	// gray

	$color_white: #fff;

	// other
	$kashmir-blue: #49668e;
	$perrywinkle: #9abbe9;
	$dark-grey-blue: #333c4f;
	$ship-cove: #7694bd;
	$zircon: #fcfdff;
	$mid-night-blue: #032D66;
	$alto: #dcdcdc;
	$link-water: #e6edf7;
	$porcelain: #eff0f1;
	$athens_gray: rgba(190, 201, 218, 0.5);

	// success
	$success: #37d58d;

	// danger
	$pastel-red: #e45251;
	$danger: #d0021b;
	$danger-light: #ff0000;
	$danger-dark: #a8515b;
	$danger-2: #ff2525;

	// warning
	$warning: #f5a623;

	$light-grayish-blue: #edf1fa;
	$light-grayish-blue: #f1f5fd;
	$desaturated-dark-blue: #536f96;	

// icons
	$action_icon_gray: #6483a7;
	$action_icon_gray-3: rgba($action_icon_gray, 0.3);
	$action_icon_gray-4: rgba($action_icon_gray, 0.4);
	$action_icon_gray-6: rgba($action_icon_gray, 0.6);
	$action_icon_hover_color: $brand_blue;
	$action_icon_bg_color: #f6f8fc;
	$action_icon_bg_color-hover: $gray_light;
	$action_icon-hover-transition: 0.1s;

// font family
	// nunito sans
	$ff-ns-regular: 'Nunito Sans Regular', sans-serif;
	$ff-ns-semi-bold: 'Nunito Sans Semi Bold', sans-serif;
	$ff-ns-bold: 'Nunito Sans Bold', sans-serif;
	$ff-ns-extra-bold: 'Nunito Sans Extra Bold', sans-serif;
	// space mono
	$ff-sm-regular: 'Space Mono Regular', sans-serif;
	$ff-sm-bold: 'Space Mono Bold', sans-serif;

// header app

	$ef-app-header-height: 64px;

// borders
	$ef-border-color-light: rgba(139, 153, 162, 0.3);

// inputs (input, textareas, dropdowns, date-pickers)

	// default
	$ef-input-height: 40px;
	$ef-input-height-small: 32px;
	$ef-input-border: solid 1px transparent;
	$ef-input-bg-color: #f1f5fd;
	$ef-input-text-color: $brand_gray;
	$ef-input-box-shadow: none;//inset 0 1px 3px 0 rgba(83, 111, 150, 0.3);
	$ef-input-box-shadow-focus: 0 2px 8px 0 rgba(101, 163, 235, 0.3);
	$ef-input-radius: 3px;
	$ef-input-hover-border: solid 1px $dodger-blue;
	$ef-input-placeholder-color: rgba(83, 111, 150, 0.5);
	$ef-input-group-suf-pref-bg-color: #fafbfc;
	$ef-input-group-suf-pref-shadow:0 1px 3px 0 rgba(100, 131, 167, 0.3);

	// disabled
	$ef-input-disabled-border-color: rgba(139, 153, 162, 0.2);
	$ef-input-disabled-border: 1px solid $ef-input-disabled-border-color;
	$ef-input-disabled-text-color: #8b99a2;
	$ef-input-disabled-shadow: none;
	$ef-input-disabled-bg: #f8f8f8;

	// focus
	$ef-input-focus-shadow:0 2px 8px 0 rgba(101, 163, 235, 0.3);

	// error
	$ef-input-error-border-color: rgba(221, 20, 18, 0.73);//rgba(221, 20, 18, 0.73);
	$ef-input-error-bg-color: #fef6f7;

// buttons

	$ef-btn-font-size-small: 10px;
	$ef-btn-default-border: solid 1px rgba(100, 131, 167, 0.6);;
	$ef-btn-font-size: 12px;
	$ef-btn-font-size-large: 14px;
	$ef-btn-height-small: 32px;
	$ef-btn-height: 40px;
	$ef-btn-height-large: 56px;
	$ef-btn-radius-small: 2px;
	$ef-btn-radius: 3px;
	$ef-btn-radius-large: 3px;


// checkbox

	$ef-checkbox-disabled-bg-color: $gray_disable;
	$ef-checkbox-disabled-mark-color: rgba(83, 111, 150, 0.2);

// radio

// select (ng-select)

	$ef-select-height: 40px;
	$ef-select-height-small: 32px;
	$ef-select-border: 1px solid transparent;
	$ef-select-box-shadow: none;//inset 0 1px 3px 0 rgba(83, 111, 150, 0.3);
	$ef-select-bg-color: $ef-input-bg-color;
	$ef-select-bg-color-opened: #fff;
	$ef-select-border-radius: $ef-input-radius;
	$ef-select-triangle-color: $brand_blue; // rotate triangle in right side

// tooltip

	$ef-tooltip-opacity: 0.85;
	$ef-tooltip-bg-color: $mid-night-blue;

// -------------- z-indexes

	// dropdown (ngx-bootstrap)
	$z-i-app-header: 3;
	$z-i-dropdowns: 1500;
	$z-i-modals: 1050;  // !!! it is native value. change is forbidden - nned for multiple windows
	$z-i-notific-push: 2000;

// lists

	$ef-list-item-bg-color: #fafbfc;
	$ef-list-item-border-bottom: 1px solid $athens_gray;
	$ef-list-item-hover-bg-color: $gray_light;
	$ef-list-item-hover-bg-color-2: rgba(42, 162, 250, 0.1);

// DRAG OPERATIONS

	// .gu-transit - class for transit element (blue shadow)
	$ef-gu-transit-opacity: 1;
	$ef-gu-transit-bg-color: rgba($dodger-blue, 0.6);
	$ef-gu-transit-border-radius: 2px;
	$ef-gu-transit-box-shadow: none!important;

	// gu-mirror - dragged element (rotated)
	$ef-gu-mirror-opacity: 1;


// MENU APP

	$ef-app-menu-width-sm: 64px;
	$ef-app-menu-item-small-size: 64px;
	$ef-app-menu-width: 264px;
	$app-menu-breakpoint: 992px;

// DASHBOARD

	// widgets
	$ef-dashb-widget-header-height: 48px;
	$ef-dashb-widget-header-bg-color: $brand_gray;
	$ef-dashb-widget-shadow:  0 1px 1px 0 rgba(83, 111, 150, 0.1);
	$ef-dashb-widget-border-radius:  2px;
	$ef-dashb-widget-body-height:  600px;

// PROCESSES

	$client-overview-activity-list-width: 240px;

// ACTIONS (create)

	// create items (stages + controls) by drag
	$ef-process-build-create-menu-width: 264px;

	// header panel
	$ef-process-build-header-panel-height: 72px;

	// navigation top panel
	$process-build-navigation-panel-height: 48px;

	// workflow
	$ef-wf-stage-card-width: 320px;
	$ef-wf-stage-card-height: 160px;
	$process-build-height-to-next-stage: 30px;
	$process-build-between-stage-line-color: rgba(#2aa2fa, 0.4);
	$process-build-distance-to-add-stage-ctrl: 30px;
	$process-build-add-stage-ctrl-border: $process-build-between-stage-line-color;

	// form-view
	$form-view-left-bar-control-size: 104px;

	// form-controls
	$ef-form-control-disabled-bg-color: rgba(139, 153, 162, 0.1);

	// stage settings
	$ef-drop-zone-height: 80px;
	$ef-section-controls-width: 150px;

// APP NOTIFICATIONS

	// type - events
	$ef-notific-item-bg-color-not-read: rgba(56, 194, 255, 0.1);
	$ef-notific-item-bg-color-hover: rgba(42, 162, 250, 0.1);
	$ef-notific-item-border-bottom: $ef-list-item-border-bottom;

	// type - result
	$notific-type-result-bg-color: #264a79;
	$notific-type-result-border-radius: 5px;
	$notific-type-result-box-shadow: 0 4px 16px 0 rgba(80, 104, 140, 0.3);
